export const SONGS = [
    {"seq": 28, "track": 6, "en_title": "black out", "kr_title": "black out"},
    {"seq": 18, "track": 1, "en_title": "secret", "kr_title": "비밀"},
    {"seq": 38, "track": 1, "en_title": "strawberry moon", "kr_title": "strawberry moon"},
    {"seq": 28, "track": 10, "en_title": "dear name", "kr_title": "이름에게"},
    {"seq": 18, "track": 13, "en_title": "l'amant", "kr_title": "라망"},
    {"seq": 26, "track": 1, "en_title": "leon", "kr_title": "레옹"},
    {"seq": 31, "track": 1, "en_title": "unlucky", "kr_title": "unlucky"},
    {"seq": 39, "track": 2, "en_title": "next stop", "kr_title": "정거장"},
    {"seq": 23, "track": 7, "en_title": "boom ladi dadi", "kr_title": "꿍따리 샤바라"},
    {"seq": 20, "track": 4, "en_title": "the red shoes", "kr_title": "분홍신"},
    {"seq": 28, "track": 2, "en_title": "palette", "kr_title": "팔레트"},
    {"seq": 2, "track": 2, "en_title": "boo", "kr_title": "boo"},
    {"seq": 27, "track": 6, "en_title": "knees", "kr_title": "무릎"},
    {"seq": 36, "track": 5, "en_title": "celebrity", "kr_title": "celebrity"},
    {"seq": 36, "track": 7, "en_title": "empty cup", "kr_title": "빈 컵"},
    {"seq": 20, "track": 7, "en_title": "obliviate", "kr_title": "obliviate"},
    {"seq": 13, "track": 6, "en_title": "merry christmas in advance", "kr_title": "미리 메리 크리스마스"},
    {"seq": 16, "track": 1, "en_title": "hold my hand", "kr_title": "내 손을 잡아"},
    {"seq": 10, "track": 1, "en_title": "because i'm a woman", "kr_title": "여자라서"},
    {"seq": 18, "track": 6, "en_title": "uncle", "kr_title": "삼촌"},
    {"seq": 25, "track": 1, "en_title": "heart", "kr_title": "마음"},
    {"seq": 21, "track": 2, "en_title": "pastel crayon", "kr_title": "크레파스"},
    {"seq": 12, "track": 1, "en_title": "it's you", "kr_title": "그대네요"},
    {"seq": 2, "track": 3, "en_title": "pitiful", "kr_title": "가여워"},
    {"seq": 13, "track": 5, "en_title": "alone in the room", "kr_title": "혼자 있는 방"},
    {"seq": 28, "track": 5, "en_title": "jam jam", "kr_title": "잼잼"},
    {"seq": 18, "track": 12, "en_title": "4am", "kr_title": "4am"},
    {"seq": 36, "track": 9, "en_title": "ah puh", "kr_title": "어푸"},
    {"seq": 2, "track": 1, "en_title": "looking at you", "kr_title": "바라보기"},
    {"seq": 23, "track": 8, "en_title": "uhuya doongi doongi", "kr_title": "어허야 둥기둥기"},
    {"seq": 19, "track": 3, "en_title": "i really don't like her", "kr_title": "그 애 참 싫다"},
    {"seq": 22, "track": 1, "en_title": "not spring, love, or cherry blossoms", "kr_title": "봄 사랑 벚꽃 말고"},
    {"seq": 35, "track": 1, "en_title": "into the i-land", "kr_title": "into the i-land"},
    {"seq": 29, "track": 4, "en_title": "last night story", "kr_title": "어젯밤 이야기"},
    {"seq": 32, "track": 1, "en_title": "first winter", "kr_title": "첫 겨울이니까"},
    {"seq": 20, "track": 11, "en_title": "daydream", "kr_title": "한낮의 꿈"},
    {
        "seq": 2,
        "track": 7,
        "en_title": "face to face (after looking at you)",
        "en_alt": "face to face",
        "kr_title": "마주보기 (바라보기 그 후)",
        "kr_alt": "마주보기"
    },
    {"seq": 23, "track": 5, "en_title": "the meaning of you", "en_alt": "meaning of you", "kr_title": "너의 의미"},
    {"seq": 23, "track": 1, "en_title": "my old story", "kr_title": "나의 옛날이야기"},
    {"seq": 24, "track": 1, "en_title": "sogyeokdong", "kr_title": "소격동"},
    {"seq": 31, "track": 6, "en_title": "love poem", "kr_title": "love poem"},
    {
        "seq": 20,
        "track": 3,
        "en_title": "between the lips (50cm)",
        "en_alt": "between the lips",
        "kr_title": "입술 사이 (50cm)",
        "kr_alt": "입술 사이"
    },
    {"seq": 31, "track": 3, "en_title": "blueming", "kr_title": "blueming"},
    {"seq": 36, "track": 4, "en_title": "hi spring bye", "kr_title": "봄 안녕 봄"},
    {"seq": 23, "track": 2, "en_title": "flower", "kr_title": "꽃"},
    {"seq": 36, "track": 1, "en_title": "lilac", "kr_title": "라일락"},
    {"seq": 18, "track": 10, "en_title": "teacher", "kr_title": "teacher"},
    {"seq": 11, "track": 1, "en_title": "i believe in love", "kr_title": "사랑을 믿어요"},
    {"seq": 28, "track": 3, "en_title": "ending scene", "kr_title": "이런 엔딩"},
    {"seq": 23, "track": 3, "en_title": "pierrot smiles at us", "kr_title": "삐에로는 우릴 보고 웃지"},
    {"seq": 20, "track": 12, "en_title": "wait", "kr_title": "기다려"},
    {"seq": 18, "track": 5, "en_title": "wallpaper patterns", "kr_title": "벽지무늬"},
    {"seq": 40, "track": 1, "en_title": "mother nature", "kr_title": "mother nature"},
    {"seq": 17, "track": 1, "en_title": "ice flower", "kr_title": "ice flower"},
    {"seq": 33, "track": 1, "en_title": "give you my heart", "kr_title": "마음을 드려요"},
    {"seq": 31, "track": 5, "en_title": "lullaby", "kr_title": "자장가"},
    {"seq": 5, "track": 5, "en_title": "heartbeating date", "kr_title": "두근두근 데이트"},
    {"seq": 14, "track": 7, "en_title": "someday", "kr_title": "someday"},
    {"seq": 39, "track": 1, "en_title": "drama", "kr_title": "드라마"},
    {"seq": 20, "track": 13, "en_title": "voice mail", "kr_title": "voice mail"},
    {"seq": 5, "track": 3, "en_title": "marshmallow", "kr_title": "마쉬멜로우"},
    {"seq": 23, "track": 6, "en_title": "dreams in summer night", "kr_title": "여름밤의 꿈"},
    {"seq": 15, "track": 9, "en_title": "cruel fairytale", "kr_title": "잔혹동화"},
    {"seq": 36, "track": 3, "en_title": "coin", "kr_title": "coin"},
    {"seq": 5, "track": 1, "en_title": "love attack", "kr_title": "love attack"},
    {"seq": 21, "track": 1, "en_title": "friday", "kr_title": "금요일에 만나요"},
    {"seq": 18, "track": 11, "en_title": "a stray puppy", "kr_title": "길 잃은 강아지"},
    {"seq": 34, "track": 1, "en_title": "eight", "kr_title": "eight"},
    {"seq": 13, "track": 2, "en_title": "the thing i do slowly", "kr_title": "느리게 하는 일"},
    {"seq": 37, "track": 1, "en_title": "nakka", "kr_title": "낙하"},
    {"seq": 13, "track": 4, "en_title": "the night of the first breakup", "kr_title": "첫 이별 그날 밤"},
    {"seq": 39, "track": 3, "en_title": "winter sleep", "kr_title": "겨울잠"},
    {"seq": 29, "track": 1, "en_title": "autumn morning", "kr_title": "가을 아침"},
    {"seq": 6, "track": 1, "en_title": "danny boy", "kr_title": "danny boy"},
    {"seq": 31, "track": 4, "en_title": "above the time", "kr_title": "시간의 바깥"},
    {"seq": 39, "track": 5, "en_title": "love letter", "kr_title": "러브레터"},
    {"seq": 20, "track": 2, "en_title": "everybody has secrets", "kr_title": "누구나 비밀은 있다"},
    {"seq": 1, "track": 2, "en_title": "lost child", "kr_title": "미아"},
    {"seq": 27, "track": 5, "en_title": "red queen", "kr_title": "red queen"},
    {"seq": 27, "track": 1, "en_title": "shoes", "kr_title": "새 신발"},
    {"seq": 9, "track": 2, "en_title": "rain drop", "kr_title": "rain drop"},
    {"seq": 18, "track": 3, "en_title": "a child searching for stars", "kr_title": "별을 찾는"},
    {"seq": 28, "track": 9, "en_title": "love alone", "kr_title": "그렇게 사랑은"},
    {"seq": 30, "track": 1, "en_title": "bbibbi", "kr_title": "bbibbi"},
    {"seq": 20, "track": 9, "en_title": "havana", "kr_title": "havana"},
    {"seq": 2, "track": 5, "en_title": "four without me", "kr_title": "나 말고 넷"},
    {"seq": 29, "track": 3, "en_title": "sleepless rainy night", "kr_title": "잠 못 드는 밤 비는 내리고"},
    {"seq": 28, "track": 1, "en_title": "dlwlrma", "kr_title": "이 지금"},
    {"seq": 20, "track": 1, "en_title": "love of b", "kr_title": "을의 연애"},
    {"seq": 39, "track": 4, "en_title": "you", "kr_title": "너"},
    {"seq": 20, "track": 8, "en_title": "walk with me, girl", "kr_title": "아이야 나랑 걷자"},
    {"seq": 28, "track": 8, "en_title": "through the night", "kr_title": "밤편지"},
    {"seq": 36, "track": 2, "en_title": "flu", "kr_title": "flu"},
    {"seq": 7, "track": 1, "en_title": "first love", "kr_title": "첫사랑이죠"},
    {"seq": 5, "track": 4, "en_title": "morning tears", "kr_title": "아침 눈물"},
    {"seq": 28, "track": 4, "en_title": "can't love you anymore", "kr_title": "사랑이 잘"},
    {"seq": 13, "track": 1, "en_title": "this is not what i thought", "kr_title": "이게 아닌데"},
    {"seq": 18, "track": 9, "en_title": "last fantasy", "kr_title": "last fantasy"},
    {"seq": 2, "track": 6, "en_title": "graduation day", "kr_title": "졸업 하는 날"},
    {"seq": 13, "track": 3, "en_title": "good day", "kr_title": "좋은 날"},
    {"seq": 29, "track": 6, "en_title": "everyday with you", "kr_title": "매일 그대와"},
    {"seq": 19, "track": 2, "en_title": "every end of the day", "kr_title": "하루 끝"},
    {"seq": 36, "track": 8, "en_title": "my sea", "kr_title": "아이와 나의 바다"},
    {"seq": 31, "track": 2, "en_title": "the visitor", "kr_title": "그 사람"},
    {"seq": 4, "track": 2, "en_title": "wind flower", "kr_title": "wind flower"},
    {"seq": 27, "track": 7, "en_title": "glasses", "kr_title": "안경"},
    {"seq": 27, "track": 3, "en_title": "twenty-three", "kr_title": "스물셋"},
    {"seq": 1, "track": 5, "en_title": "every sweet day", "kr_title": "every sweet day"},
    {"seq": 27, "track": 4, "en_title": "the shower", "kr_title": "푸르던"},
    {"seq": 20, "track": 10, "en_title": "a gloomy clock", "kr_title": "우울시계"},
    {"seq": 36, "track": 10, "en_title": "epilogue", "kr_title": "에필로그"},
    {"seq": 18, "track": 8, "en_title": "everything's alright", "kr_title": "everything's alright"},
    {"seq": 1, "track": 3, "en_title": "you know", "kr_title": "있잖아"},
    {"seq": 4, "track": 1, "en_title": "to the sea", "kr_title": "to the sea"},
    {"seq": 29, "track": 2, "en_title": "secret garden", "kr_title": "비밀의 화원"},
    {"seq": 28, "track": 7, "en_title": "full stop", "kr_title": "마침표"},
    {"seq": 36, "track": 6, "en_title": "troll", "kr_title": "돌림노래"},
    {"seq": 9, "track": 1, "en_title": "nagging", "kr_title": "잔소리"},
    {"seq": 15, "track": 8, "en_title": "only i didn't know", "kr_title": "나만 몰랐던 이야기"},
    {"seq": 29, "track": 5, "en_title": "by the stream", "kr_title": "개여울"},
    {"seq": 20, "track": 5, "en_title": "modern times", "kr_title": "modern times"},
    {"seq": 18, "track": 7, "en_title": "wisdom tooth", "kr_title": "사랑니"},
    {"seq": 8, "track": 1, "en_title": "fifth finger", "kr_title": "다섯째 손가락"},
    {"seq": 20, "track": 6, "en_title": "bad day", "kr_title": "싫은 날"},
    {"seq": 18, "track": 4, "en_title": "you & i", "en_alt": "you and i", "kr_title": "너랑 나"},
    {"seq": 1, "track": 1, "en_title": "ugly duckling", "kr_title": "미운 오리"},
    {"seq": 3, "track": 1, "en_title": "you are always like that", "kr_title": "그러는 그대는"},
    {"seq": 19, "track": 1, "en_title": "peach", "kr_title": "복숭아"},
    {"seq": 23, "track": 4, "en_title": "when love passes by", "kr_title": "사랑이 지나가면"},
    {"seq": 1, "track": 4, "en_title": "feel so good", "kr_title": "feel so good"},
    {"seq": 27, "track": 2, "en_title": "zezé", "kr_title": "zezé"},
    {"seq": 18, "track": 2, "en_title": "sleeping prince of the woods", "kr_title": "잠자는 숲속의 왕자"},
    {"seq": 5, "track": 2, "en_title": "taking a train", "kr_title": "기차를 타고"},
    {"seq": 2, "track": 4, "en_title": "a dreamer", "kr_title": "a dreamer"}
]
