import {BaseModal} from './BaseModal'

type Props = {
    isOpen: boolean
    handleClose: () => void
}

export const InfoModalKorean = ({isOpen, handleClose}: Props) => {
    return (
        <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                이 번역은 파파고를 통해서만 가능합니다. 더 나은 번역을 제공할 수 있는지 알려주세요.<br/><br/>
                아이유 노래 여섯 번 만에 맞춰보세요. 이 예에서는 노래가 "이런 엔딩"입니다.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                BLUEMING &#x2B07;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                그 노래는 발매일보다 더 이른 날짜의 노래입니다.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                좋은 날 &#x2B06;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                그 노래는 이후 발매일에 나온 것이다.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                마침표 &#x2B05;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                이 곡은 같은 앨범이지만 트랙리스트의 앞부분의 곡입니다.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                이 지금 &#x27A1;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                그 노래는 같은 앨범에 수록되었지만 나중에 트랙리스트에 수록된다.
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-300 mt-2">
                이것은 대소문자를 구분하지 않습니다. 대시, 아포스트로피 및 쉼표와 같은 특수 문자에도 민감하지 않습니다. INTO THE ILAND는 INTO THE ILAND와 같은 의미로 간주된다.
                <br/><br/>
                <b>중요:</b> 단순화를 위해 사전 발매는 앨범의 동일한 발매 날짜에 계산됩니다. EX: CELEBRITY 발매일은 2021년 3월 25일로 집계되며, 이는 LILAC 앨범 발매일과 동일합니다.
                <br/><br/>
                사운드트랙이 포함되어 있습니다. 아이유가 피처링만 하는 곡들은 (예: SOULMATE, 연애소설)가 아닌 듀엣 콜라보레이션 (낙하, 레옹)이 포함되어 있다. 한국 발매만, 아이유의 일본 발매는 포함되어 있지 않습니다.
                <br/><br/>
                만약 당신이 실수를 발견한다면, mingukiu516@gmail.com으로 이메일을 보내주시기 바랍니다.
            </p>
        </BaseModal>
    )
}
