import { Cell } from './Cell'

export const EmptyRow = () => {

  return (
    <div className="flex justify-center mb-1 guess-row h-14 w-80 mx-auto items-center empty-row">
      {/*{emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}*/}
    </div>
  )
}
