import {SONGS} from '../constants/songList'

export const removeSpecialCharacters = (song?: string, debug?: boolean) => {
    if (song) {
        const rep = song.replace(/['",()-]+/g, '');
        if (debug) {
            console.log('rep: ' + rep)
        }
        return rep;
    }
    return song;
}

export const isSongInSongList = (song: string, isKoreanMode: boolean) => {
    if (isKoreanMode) {
        const length1 = SONGS.filter(s => removeSpecialCharacters(s.kr_title) === removeSpecialCharacters(song.toLowerCase().trim())).length;
        const length2 = SONGS.filter(s => removeSpecialCharacters(s.kr_alt) === removeSpecialCharacters(song.toLowerCase().trim())).length;
        return (length1 > 0 || length2)
    } else {
        return (
            SONGS.filter(s => removeSpecialCharacters(s.en_title) === removeSpecialCharacters(song.toLowerCase())).length > 0 ||
            SONGS.filter(s => removeSpecialCharacters(s.en_alt) === removeSpecialCharacters(song.toLowerCase())).length > 0
        )
    }
}

export const isWinningSong = (song: string, isKoreanMode: boolean) => {
    if (isKoreanMode) {
        return removeSpecialCharacters(solution.kr_title) === removeSpecialCharacters(song.toLowerCase())
            || removeSpecialCharacters(solution.kr_alt) == removeSpecialCharacters(song.toLowerCase())
    } else {
        return removeSpecialCharacters(solution.en_title) === removeSpecialCharacters(song.toLowerCase())
            || removeSpecialCharacters(solution.en_alt) == removeSpecialCharacters(song.toLowerCase())
    }
}

export const findSongInList = (song: string, isKoreanMode: boolean):
    {
        seq: number,
        track: number,
        en_title: string,
        en_alt?: string,
        kr_title: string,
        kr_alt?: string
    } => {
    let songs;
    if (isKoreanMode) {
        songs = SONGS.filter(s => removeSpecialCharacters(s.kr_title) === removeSpecialCharacters(song.toLowerCase())
            || removeSpecialCharacters(s.kr_alt) === removeSpecialCharacters(song.toLowerCase()));
    } else {
        songs = SONGS.filter(s => removeSpecialCharacters(s.en_title) === removeSpecialCharacters(song.toLowerCase())
            || removeSpecialCharacters(s.en_alt) === removeSpecialCharacters(song.toLowerCase()));
    }
    return songs[0];
}

export const getSongOfDay = () => {
    // February 12, 2022 Game Epoch
    const epochMs = new Date('February 12, 2022 00:00:00').valueOf()
    const now = Date.now()
    const msInDay = 86400000
    const index = Math.floor((now - epochMs) / msInDay)
    const nextday = (index + 1) * msInDay + epochMs

    return {
        solution: SONGS[index % SONGS.length],
        solutionIndex: index,
        tomorrow: nextday,
    }
}

export const {solution, solutionIndex, tomorrow} = getSongOfDay()
