import {CompletedRow} from './CompletedRow'
import {CurrentRow} from './CurrentRow'
import {EmptyRow} from './EmptyRow'

type Props = {
    guesses: string[]
    isKoreanMode: boolean
    onChar: (value: string) => void
    onEnter: () => void
    isGameWon: boolean
    cssClass: string
    currentGuess: string
    maxChallenges: number
}

export const Grid = ({guesses, isKoreanMode, onChar, onEnter, isGameWon, cssClass, currentGuess, maxChallenges}: Props) => {
    const empties =
        guesses.length < maxChallenges - 1
            ? Array.from(Array(maxChallenges - 1 - guesses.length))
            : []

    return (
        <div className="pb-6">
            {guesses.map((guess, i) => (
                <CompletedRow
                    key={i}
                    guess={guess}
                    isKoreanMode={isKoreanMode}
                />
            ))}
            {guesses.length < maxChallenges &&
            <CurrentRow onChar={onChar} onEnter={onEnter} isGameWon={isGameWon} cssClass={cssClass} currentGuess={currentGuess}/>}
            {empties.map((_, i) => (
                <EmptyRow key={i}/>
            ))}
        </div>
    )
}
