export const GAME_TITLE = 'DLWLRDLE'

export const WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!']
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'About this game'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Song not found'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The song was ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New song in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'

export const EARLIER = "EARLIER";
export const LATER = "LATER";
export const SAME_EARLIER = "SAME_EARLIER";
export const SAME_LATER = "SAME_LATER";
export const WINNER = "WINNER";

export const CANNOT_SWITCH_MODE_MID_GAME = "Cannot switch mode Mid Game";
