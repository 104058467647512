import {
    AcademicCapIcon,
    CakeIcon,
    ChartBarIcon,
    InformationCircleIcon,
    MoonIcon,
    SunIcon,
} from '@heroicons/react/outline'
import {useEffect, useState} from 'react'
import {Alert} from './components/alerts/Alert'
import {Grid} from './components/grid/Grid'
import {AboutModal} from './components/modals/AboutModal'
import {InfoModal} from './components/modals/InfoModal'
import {StatsModal} from './components/modals/StatsModal'

import {
    ABOUT_GAME_MESSAGE, CANNOT_SWITCH_MODE_MID_GAME,
    CORRECT_WORD_MESSAGE, ENTER_TEXT,
    GAME_COPIED_MESSAGE,
    GAME_TITLE,
    NOT_ENOUGH_LETTERS_MESSAGE,
    WIN_MESSAGES,
    WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import {ALERT_TIME_MS, GAME_LOST_INFO_DELAY, REVEAL_TIME_MS,} from './constants/settings'
import {addStatsForCompletedGame, loadStats} from './lib/stats'
import {loadGameStateFromLocalStorage, saveGameStateToLocalStorage,} from './lib/localStorage'

import './App.css'
import {isSongInSongList, isWinningSong, removeSpecialCharacters, solution} from "./lib/songs";
import Logo from "./Logo";
import Lang from "./Lang";
import {InfoModalKorean} from "./components/modals/InfoModalKorean";
import {set} from "husky";
import {Key} from "./components/keyboard/Key";

function App() {

    const prefersDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)'
    ).matches

    const [currentGuess, setCurrentGuess] = useState('')
    const [maxChallenges, setMaxChallenges] = useState(
        localStorage.getItem('maxChallenges') === null ? 6 : Number(localStorage.getItem('maxChallenges'))
    )
    const [isGameWon, setIsGameWon] = useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(
        localStorage.getItem('firstVisit') === null ? true : false
    )
    const [isInfoKoreanModalOpen, setIsInfoKoreanModalOpen] = useState(false)
    const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
    const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
    const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
    const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
    const [isCannotSwitchModeOpen, setIsCannotSwitchModeOpen] = useState(false)
    const [isGameLost, setIsGameLost] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(
        localStorage.getItem('theme')
            ? localStorage.getItem('theme') === 'dark'
            : prefersDarkMode
                ? true
                : false
    )
    const [successAlert, setSuccessAlert] = useState('')
    const [isRevealing, setIsRevealing] = useState(false)
    const [guesses, setGuesses] = useState<string[]>(() => {
        const loaded = loadGameStateFromLocalStorage()
        if (loaded?.solution.en_title !== solution.en_title) {
            return []
        }
        let length = loaded.guesses.filter(guess => removeSpecialCharacters(guess) === removeSpecialCharacters(solution.en_title.toUpperCase())).length;
        if (length === 0 && solution.en_alt) {
            length = loaded.guesses.filter(guess => removeSpecialCharacters(guess) === removeSpecialCharacters(solution.en_alt?.toUpperCase())).length;
        }
        let gameWasWon = length > 0;
        if (gameWasWon) {
            setIsGameWon(true)
        }
        if (loaded.guesses.length === maxChallenges && !gameWasWon) {
            setIsGameLost(true)
        }
        return loaded.guesses
    })

    const [stats, setStats] = useState(() => loadStats(maxChallenges))

    const [isKoreanMode, setIsKoreanMode] = useState(
        localStorage.getItem('gameMode')
            ? localStorage.getItem('gameMode') === 'korean'
            : false
    )

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [isDarkMode])

    const handleDarkMode = (isDark: boolean) => {
        setIsDarkMode(isDark)
        localStorage.setItem('theme', isDark ? 'dark' : 'light')
    }

    const handleMaxChallenges = (maxChallenges: number) => {
        const length = guesses.length;
        if (length > 0) {
            setIsCannotSwitchModeOpen(true)
            return setTimeout(() => {
                setIsCannotSwitchModeOpen(false)
            }, ALERT_TIME_MS)
        }
        setMaxChallenges(maxChallenges)
        localStorage.setItem('maxChallenges', ''+maxChallenges);
        setStats(loadStats(maxChallenges))
    }

    const handleInfoModalOpen = () => {
        localStorage.setItem('firstVisit', 'not');
        if (isKoreanMode) {
            setIsInfoKoreanModalOpen(!isInfoKoreanModalOpen);
        } else {
            setIsInfoModalOpen(!isInfoModalOpen);
        }
    }

    const handleKoreanMode = (isKorean: boolean) => {
        const length = guesses.length;
        if (length > 0) {
            setIsCannotSwitchModeOpen(true)
            return setTimeout(() => {
                setIsCannotSwitchModeOpen(false)
            }, ALERT_TIME_MS)
        }
        setIsKoreanMode(isKorean)
        localStorage.setItem('gameMode', isKorean ? 'korean' : 'english')
    }

    useEffect(() => {
        saveGameStateToLocalStorage({guesses, solution})
    }, [guesses])

    useEffect(() => {
        if (isGameWon) {
            setTimeout(() => {
                setSuccessAlert(
                    WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
                )

                setTimeout(() => {
                    setSuccessAlert('')
                    setIsStatsModalOpen(true)
                }, ALERT_TIME_MS)
            }, REVEAL_TIME_MS)
        }
        if (isGameLost) {
            setTimeout(() => {
                setIsStatsModalOpen(true)
            }, GAME_LOST_INFO_DELAY)
        }
    }, [isGameWon, isGameLost])

    const onChar = (value: string) => {
        if (
            guesses.length < maxChallenges &&
            !isGameWon
        ) {
            setCurrentGuess(value)
        }
    }

    const onEnter = () => {
        if (isGameWon || isGameLost) {
            return
        }

        if (currentGuess.length === 0) {
            return
        }

        if (!isSongInSongList(currentGuess, isKoreanMode)) {
            setIsWordNotFoundAlertOpen(true)
            return setTimeout(() => {
                setIsWordNotFoundAlertOpen(false)
            }, ALERT_TIME_MS)
        }

        setIsRevealing(true)
        // turn this back off after all
        // chars have been revealed
        setTimeout(() => {
            setIsRevealing(false)
        }, REVEAL_TIME_MS)

        const winningWord = isWinningSong(currentGuess, isKoreanMode)

        if (
            guesses.length < maxChallenges &&
            !isGameWon
        ) {
            setGuesses([...guesses, currentGuess])
            setCurrentGuess('')

            if (winningWord) {
                setStats(addStatsForCompletedGame(stats, guesses.length, maxChallenges))
                return setIsGameWon(true)
            }

            if (guesses.length === maxChallenges - 1) {
                setStats(addStatsForCompletedGame(stats, guesses.length + 1, maxChallenges))
                setIsGameLost(true)
            }
        }
    }

    return (
        <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="flex w-80 mx-auto items-center mb-8 mt-20">
                <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
                    <Logo></Logo>
                    {GAME_TITLE}
                </h1>
                <Lang isKoreanMode={isKoreanMode} className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
                      onClick={() => handleKoreanMode(!isKoreanMode)}></Lang>
                {isDarkMode ? (
                    <SunIcon
                        className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
                        onClick={() => handleDarkMode(!isDarkMode)}
                    />
                ) : (
                    <MoonIcon
                        className="h-6 w-6 mr-2 cursor-pointer"
                        onClick={() => handleDarkMode(!isDarkMode)}
                    />
                )}
                <InformationCircleIcon
                    className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
                    onClick={() => handleInfoModalOpen()}
                />
                <ChartBarIcon
                    className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
                    onClick={() => setIsStatsModalOpen(true)}
                />
            </div>
            <div className="flex justify-center mb-2 w-80 mx-auto items-center">
                <span className="mr-2">Number of Guesses:</span>
                <a href="#" className={"mr-2 max-challenges-link " + (maxChallenges === 6 ? "active-link" : "")}
                   onClick={() => handleMaxChallenges(6)}>6</a>
                <a href="#" className={"mr-2 max-challenges-link " + (maxChallenges === 8 ? "active-link" : "")}
                   onClick={() => handleMaxChallenges(8)}>8</a>
                <a href="#" className={"mr-2 max-challenges-link " + (maxChallenges === 10 ? "active-link" : "")}
                   onClick={() => handleMaxChallenges(10)}>10</a>
            </div>
            <div className="flex justify-center mb-8 h-14 w-80 mx-auto">
                <Key width={300} value="ENTER" onClick={onEnter}>
                    {ENTER_TEXT}
                </Key>
            </div>
            <Grid
                guesses={guesses}
                isKoreanMode={isKoreanMode}
                onChar={onChar}
                onEnter={onEnter}
                isGameWon={isGameWon}
                cssClass={isDarkMode ? "dark" : ""}
                currentGuess={currentGuess}
                maxChallenges={maxChallenges}
            />
            <div className="flex justify-center mb-5 h-14 w-80 mx-auto">
                <Key width={300} value="ENTER" onClick={onEnter}>
                    {ENTER_TEXT}
                </Key>
            </div>
            <InfoModal
                isOpen={isInfoModalOpen}
                handleClose={() => handleInfoModalOpen()}
            />
            <InfoModalKorean
                isOpen={isInfoKoreanModalOpen}
                handleClose={() => handleInfoModalOpen()}
            />
            <StatsModal
                isOpen={isStatsModalOpen}
                handleClose={() => setIsStatsModalOpen(false)}
                guesses={guesses}
                gameStats={stats}
                isGameLost={isGameLost}
                isGameWon={isGameWon}
                handleShare={() => {
                    setSuccessAlert(GAME_COPIED_MESSAGE)
                    return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
                }}
                isKoreanMode={isKoreanMode}
                maxChallenges={maxChallenges}
            />
            <AboutModal
                isOpen={isAboutModalOpen}
                handleClose={() => setIsAboutModalOpen(false)}
            />

            <button
                type="button"
                className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
                onClick={() => setIsAboutModalOpen(true)}
            >
                {ABOUT_GAME_MESSAGE}
            </button>

            <Alert message={CANNOT_SWITCH_MODE_MID_GAME} isOpen={isCannotSwitchModeOpen}/>

            <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters}/>
            <Alert
                message={WORD_NOT_FOUND_MESSAGE}
                isOpen={isWordNotFoundAlertOpen}
            />
            <Alert
                message={CORRECT_WORD_MESSAGE(solution.en_title.toUpperCase())}
                isOpen={isGameLost && !isRevealing}
            />
            <Alert
                message={successAlert}
                isOpen={successAlert !== ''}
                variant="success"
            />
        </div>
    )
}

export default App
