import {getGuessStatuses} from './statuses'
import {solutionIndex} from './songs'
import {EARLIER, GAME_TITLE, LATER, SAME_EARLIER, SAME_LATER} from '../constants/strings'
const emojify = require('node-emoji')

export const shareStatus = (
    guesses: string[],
    lost: boolean,
    isKoreanMode: boolean,
    maxChallenges: number
) => {
    const text = `${GAME_TITLE} ${solutionIndex} ${lost ? 'X' : guesses.length}/${maxChallenges}${
        isKoreanMode ? emojify.emojify(':kr:') : ''
    }%0a%0a` + generateEmojiGrid(guesses, isKoreanMode) + `%0a%0a`
    navigator.clipboard.writeText(text);
    const twtShare = 'https://twitter.com/intent/tweet?url=https://dlwlrdle.kamille.dev/&text='+text;
    window.open(twtShare, '_blank');
}

export const generateEmojiGrid = (guesses: string[], isKoreanMode: boolean) => {
    return guesses
        .map((guess) => {
            const status = getGuessStatuses(guess, isKoreanMode)
            let emoji = '';

            switch (status) {
                case EARLIER:
                    emoji = emojify.emojify(':arrow_down:');
                    break;
                case LATER:
                    emoji = emojify.emojify(':arrow_up:');
                    break;
                case SAME_EARLIER:
                    emoji = emojify.emojify(':arrow_left:');
                    break;
                case SAME_LATER:
                    emoji = emojify.emojify(':arrow_right:');
                    break;
                default:
                    emoji = emojify.emojify(':hatched_chick:');
                    break;
            }
            return emoji
        })
        .join('%0a')
}
