import { solution, findSongInList } from './songs'
import {EARLIER, LATER, SAME_EARLIER, SAME_LATER, WINNER} from "../constants/strings";

export type CharStatus = 'absent' | 'present' | 'correct'

export const getGuessStatuses = (guess: string, isKoreanMode: boolean): string => {
  const seq = solution.seq;
  const track = solution.track;
  const songInList = findSongInList(guess, isKoreanMode);

  if (!songInList) {
    return '';
  }

  if (songInList.seq > seq) {
    return EARLIER;
  } else if (songInList.seq < seq) {
    return LATER;
  } else {
    if (songInList.track > track) {
      return SAME_EARLIER;
    } else if (songInList.track < track) {
      return SAME_LATER;
    }
  }

  return WINNER;
}
