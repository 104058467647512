import React from 'react';
import kr from './assets/img/kr.jpg';
import en from './assets/img/en.jpg';

function Lang(props: { isKoreanMode: boolean, className: string, onClick: any }) {
    // Import result is the URL of your image
    if (props.isKoreanMode) {
        return <img src={kr} alt="kr" className={props.className} onClick={props.onClick}/>;
    } else {
        return <img src={en} alt="en" className={props.className} onClick={props.onClick}/>;
    }
}

export default Lang;
