import {getGuessStatuses} from '../../lib/statuses'
import {Cell} from './Cell'
import {MoonIcon, SunIcon} from "@heroicons/react/outline";
import {EARLIER, LATER, SAME_EARLIER, SAME_LATER, WINNER} from "../../constants/strings";

type Props = {
    guess: string
    isKoreanMode: boolean
}

export const CompletedRow = ({guess, isKoreanMode}: Props) => {
    const status = getGuessStatuses(guess, isKoreanMode)
    /*
        {isDarkMode ? (
            <SunIcon
                className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
                onClick={() => handleDarkMode(!isDarkMode)}
            />
        ) : (
            <MoonIcon
                className="h-6 w-6 mr-2 cursor-pointer"
                onClick={() => handleDarkMode(!isDarkMode)}
            />
        )}*/

    return (
        <div className="flex justify-center mb-1 guess-row h-14 w-80 mx-auto items-center">
            <div className="flex justify-left guess mr-2">
                {guess}
            </div>
            <div className="flex justify-right">
                {(status === EARLIER) && (
                    <span>&#x2B07;</span>
                )}
                {(status === LATER) && (
                    <span>&#x2B06;</span>
                )}
                {(status === SAME_EARLIER) && (
                    <span>&#x2B05;</span>
                )}
                {(status === SAME_LATER) && (
                    <span>&#x27A1;</span>
                )}
                {(status === WINNER) && (
                    <span>&#x1F425;</span>
                )}
            </div>
        </div>
        /*<div className="flex justify-center mb-1">
          {guess.split('').map((letter, i) => (
            <Cell
              key={i}
              value={letter}
              status={statuses[i]}
              position={i}
              isRevealing={isRevealing}
              isCompleted
            />
          ))}
        </div>*/
    )
}
