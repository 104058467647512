import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

// In stats array elements 0-5 are successes in 1-6 trys

export const addStatsForCompletedGame = (
  gameStats: GameStats,
  count: number,
  maxChallenges: number
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats }

  stats.totalGames += 1

  if (count >= maxChallenges) {
    // A fail situation
    stats.currentStreak = 0
    stats.gamesFailed += 1
  } else {
    if (stats.winDistribution.length < maxChallenges) {
      let newArr = Array.from(new Array(maxChallenges-stats.winDistribution.length), () => 0);
      stats.winDistribution = stats.winDistribution.concat(newArr)
    }
    stats.winDistribution[count] += 1
    stats.currentStreak += 1

    if (stats.bestStreak < stats.currentStreak) {
      stats.bestStreak = stats.currentStreak
    }
  }

  stats.successRate = getSuccessRate(stats)

  saveStatsToLocalStorage(stats)
  return stats
}

export const loadStats = (maxChallenges: number) => {
  function getDefaultStats(maxChallenges: number) {
    const defaultStats: GameStats = {
      winDistribution: Array.from(new Array(maxChallenges), () => 0),
      gamesFailed: 0,
      currentStreak: 0,
      bestStreak: 0,
      totalGames: 0,
      successRate: 0,
    }
    return defaultStats;
  }

  return loadStatsFromLocalStorage() || getDefaultStats(maxChallenges)
}

const getSuccessRate = (gameStats: GameStats) => {
  const { totalGames, gamesFailed } = gameStats

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
  )
}
