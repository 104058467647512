import {BaseModal} from './BaseModal'

type Props = {
    isOpen: boolean
    handleClose: () => void
}

export const InfoModal = ({isOpen, handleClose}: Props) => {
    return (
        <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                Guess the IU song in 6 tries. In this example, the song is "Ending Scene"
            </p>

            <div className="flex justify-center mb-1 mt-4">
                BLUEMING &#x2B07;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                The song is from an earlier release date.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                GOOD DAY &#x2B06;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                The song is from a later release date.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                FULL STOP &#x2B05;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                The song is from the same album but earlier in the tracklist.
            </p>

            <div className="flex justify-center mb-1 mt-4">
                DLWLRMA &#x27A1;
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                The song is from the same album but later in the tracklist.
            </p>

            <p className="text-sm text-gray-500 dark:text-gray-300 mt-2">
                This is not case sensitive. Also not sensitive to special characters such as dashes, apostrophe, and comma. Ex: INTO THE ILAND is counted same as INTO THE I-LAND.<br/><br/>
                <b>IMPORTANT:</b> For simplicity purposes, pre-release are counted into the same release date of their albums. Ex: CELEBRITY release date is counted as March 25, 2021 - same as LILAC album release date.<br/><br/>
                Soundtracks are included. Songs where IU is only featured are not included (ex. Soulmate, Love Story) but duet collaborations (NAKKA, LEON) are. Korean releases only, IU's Japanese releases are not included.
                <br/><br/>
                If you find any mistakes please let me know by email at mingukiu516@gmail.com
            </p>
        </BaseModal>
    )
}
