import React, {useEffect, useState} from "react";

type Props = {
    onChar: (value: string) => void
    onEnter: () => void
    isGameWon: boolean
    cssClass: string
    currentGuess: string
}

export const CurrentRow = ({onChar, onEnter, isGameWon, cssClass, currentGuess}: Props) => {
    //const [textValue, setTextValue] = useState('')
    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.code === 'Enter') {
                onEnter()
                //setTextValue('')
            }
        }
        window.addEventListener('keyup', listener)
        return () => {
            window.removeEventListener('keyup', listener)
        }
    }, [onEnter])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const val = e.currentTarget.value.toUpperCase();
        //setTextValue(val)
        onChar(val);
    }

    return (
        <div className="flex justify-center mb-1 guess-row h-14 w-80 mx-auto items-center">
            <input type="text" className={cssClass} autoFocus={!isGameWon} readOnly={isGameWon}
                   onChange={(e) => {handleChange(e)}} value={currentGuess}/>
        </div>
    )
}
